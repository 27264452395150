var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error403" }, [
    _c("div", { staticClass: "error403-body" }, [
      _c(
        "div",
        { staticClass: "error403-body-backDoor", on: { click: _vm.backDoor } },
        [_vm._v("返回门户")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }