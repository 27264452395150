<template>
  <div class="error403">
    <div class="error403-body">
      <div class="error403-body-backDoor" @click="backDoor">返回门户</div>
    </div>
  </div>
</template>

<script>
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
export default {
  name: 'Error403',
  data() {
    return {};
  },
  methods: {
    backDoor() {
      StorageUtil.clearSessionStorage();
      window.location.href = process.env.VUE_APP_PORTAL_URL;
    }
  }
};
</script>
<style lang="scss" scoped>
.error403 {
  .error403-body {
    height: 100vh;
    width: 100vw;
    background: url('../../assets/image/403/noRole.png') no-repeat;
    background-position: center;
    position: relative;
    &-backDoor {
      color: #409eff;
      font-size: var(--rootFontSize);
      font-weight: 600;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(0, 60%);
    }
    &-backDoor:hover {
      cursor: pointer;
    }
  }
}
</style>
